var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center ml-3 flex-wrap"},[_c('RangeDateSelector',{ref:"dateSelector",attrs:{"start-date":_vm.startDate,"end-date":_vm.endDate,"loading":_vm.loadingClosures,"disabled":_vm.loadingClosures,"max-width":!!this.$vuetify.breakpoint.xs ? '80vw' 
          : this.$vuetify.breakpoint.sm ? '65vw': '50vw'},on:{"update:startDate":function($event){_vm.startDate=$event},"update:start-date":function($event){_vm.startDate=$event},"update:endDate":function($event){_vm.endDate=$event},"update:end-date":function($event){_vm.endDate=$event}}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","disabled":!_vm.startDate || !_vm.endDate,"loading":_vm.loadingClosures},on:{"click":_vm.fetchClosures}},[_vm._v("Calcola")])],1),(_vm.cashDesksClosures.length > 0)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-subheader',{staticClass:"text-h6 py-1"},[_vm._v("Casse")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.collapseCashDeskSection}},[_c('v-icon',{staticClass:"collapser-icon",class:{
              expanded: !_vm.collapsedCashDesk,
              collapsed: _vm.collapsedCashDesk,
            }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-expand-transition',[(!_vm.collapsedCashDesk)?_c('div',{staticClass:"d-flex flex-wrap ml-2"},_vm._l((_vm.cashDesksClosures),function(cashDesk){return _c('CalculatorCard',{key:cashDesk.cashDeskId,staticClass:"ma-2",staticStyle:{"height":"fit-content"},attrs:{"object":cashDesk,"can-view-ghost":_vm.canViewGhost,"title":cashDesk.cashDeskName,"cashFund":cashDesk.cashDeskCashFund}})}),1):_vm._e()])],1):_vm._e(),(!!_vm.totalClosures)?_c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-subheader',{staticClass:"text-h6 py-1"},[_vm._v("Totale")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.collapseTotalSection}},[_c('v-icon',{staticClass:"collapser-icon",class:{
              expanded: !_vm.collapsedTotal,
              collapsed: _vm.collapsedTotal,
            }},[_vm._v("mdi-chevron-down")])],1)],1),_c('v-expand-transition',[(!_vm.collapsedTotal)?_c('div',{staticClass:"d-flex flex-wrap"},[_c('CalculatorCard',{staticClass:"ma-2",attrs:{"object":_vm.totalClosures,"can-view-ghost":_vm.canViewGhost,"title":"Totale"}})],1):_vm._e()])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }