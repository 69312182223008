<template>
  <div>
    <FullScreenDialog
      v-model="closureDialog"
      title="Corrispettivi"
      :routeFather="pathToGoBack"
      @input="$emit('update:open-dialog', $event)"
    >
      <template v-slot:toolbar-extension>
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab
            v-for="tab in closureTabs"
            :key="tab.key"
          >{{ tab.title }}</v-tab>
        </v-tabs>
      </template>

      <template v-slot:header-actions>
        <v-btn
          icon
          @click="toggleBarcode"
          :color="!!canViewGhost ? 'red' : ''"
        >
          <v-icon>mdi-shield-account-variant</v-icon>
        </v-btn>
      </template>

      <template v-slot:content>
        <v-tabs-items v-model="tab" class="mt-1">
          <v-tab-item v-for="tabItem in closureTabs" :key="tabItem.key">
            <div v-if="tabItem.component == 'CashDeskTransactionTable'" style="height: 100%">
              <CashDeskTransactionTable
                :bus="bus"
              ></CashDeskTransactionTable>
            </div>
            <v-card flat v-else>
              <component 
                :is="tabItem.component"
                :bus="bus"
                :canViewGhost="canViewGhost"
              ></component>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>

      <template v-slot:footer-actions>
        <div>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="error"
            @click="closeDialog"
          >Annulla</v-btn>
        </div>
      </template>
    </FullScreenDialog>
    <StandardDialog
      v-model="dialogBarcode"
      title="Area Riservata Sviluppatori" 
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput
        v-model="operatorBarcode"
        scan-your-barcode-message="Immettere Codice"
        @confirm="activeBarcode"
      >
      </ManualBarcodeInput>
    </StandardDialog>

    <StandardDialog
      v-model="errorDialogBarcode"
      :dialog-height="null"
      :dialog-width="null"
    >
      <v-alert
        class="mt-3"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{messagErrorDialog}}
      </v-alert>
    </StandardDialog>
  </div>
</template>

<script>
import Vue from 'vue';
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import MonthlyClosure from '@/components/areas/payments/closures/MonthlyClosure.vue';
import ClosureCustom from '@/components/areas/payments/closures/ClosureCustom.vue';
import ClosureDetails from '@/components/areas/payments/closures/ClosureDetails.vue';
import CashDeskTransactionTable from '@/components/areas/payments/paymentTransactions/CashDeskTransactionTable.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import operatorService from '@/services/operators/operators.service.js'

export default {
  name: "ClosureHome",
  components: {
    FullScreenDialog,
    MonthlyClosure,
    ClosureCustom,
    CashDeskTransactionTable,
    ManualBarcodeInput,
    StandardDialog,
    ClosureDetails
  },
  data: function () {
    return {
      tab: null,
      closureDialog: true,
      closureTabs: [
        {
          key: 0,
          title: "Corrispettivi",
          name: "closureCustom",
          component: "ClosureCustom",
        },
        {
          key: 1,
          title: "Corrispettivi Mensili",
          name: "monthly",
          component: "MonthlyClosure",
        },
        {
          key: 2,
          title: "Dettaglio",
          name: "details",
          component: "ClosureDetails",
        },
        /*{
          key: 3,
          title: "Transazioni",
          name: "transaction",
          component: "CashDeskTransactionTable",
        },*/
      ],
      currentRoute: undefined,
      originalUrl: window.location.pathname,
      bus: new Vue(),
      dialogBarcode: false,
      operatorBarcode: undefined,
      errorDialogBarcode: false,
      messagErrorDialog: '',
      canViewGhost: false,
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/payments",
    },
    tabName: {
      type: String,
      default: 'payments'
    },
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.closureTabs.length; i++) {
        if(this.closureTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    closeDialog() {
      if(this.pathToGoBack) {
        this.$router.push({path: this.pathToGoBack})
      } else {
        this.closureDialog = false
        this.$emit('update:open-dialog', false)
      }
    },
    toggleBarcode(){
      this.dialogBarcode = !this.dialogBarcode
    },
    activeBarcode(){
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.canViewGhost = true
          //this.bus.$emit('reload')
          this.$delegates.snackbar('Operatore autorizzato')
        } else {
          this.messagErrorDialog = 'Operatore non autorizzato'
          this.errorDialogBarcode = true
        }
      }).catch(() => {
        this.messagErrorDialog = 'Codice non riconosciuto'
        this.errorDialogBarcode = true
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
  },
  watch: {
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.closureTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    },
    openDialog(newVal) {
      this.closureDialog = newVal;
    },
  }
};
</script>

<style>
</style>