<template>
  <div>
    <div class="d-flex align-center ml-3 flex-wrap">
      <RangeDateSelector
          ref="dateSelector"
          :start-date.sync="startDate"
          :end-date.sync="endDate"
          :loading="loadingClosures"
          :disabled="loadingClosures"
          :max-width="!!this.$vuetify.breakpoint.xs ? '80vw' 
            : this.$vuetify.breakpoint.sm ? '65vw': '50vw' "
        ></RangeDateSelector>
      <v-btn 
        class="mr-2" 
        text :disabled="!startDate || !endDate" 
        @click="fetchClosures" :loading="loadingClosures">
        Calcola
      </v-btn>

      <ExportExcelTable
        :disable="!startDate || !endDate"
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
    </div>
    <div ref="dataTableWrapper">
      <TypeDataTable
        disable-pagination
        no-data-text="Nessun dato selezionato"
        :headers="headers"
        :items="closures"
        :height="'65vh'"
        :show-actions="false"
        :show-select="false"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
      >
        <template v-slot:externalFooter>
          <TypeDataTable
            :headers="headers"
            :items="totalRow"
            :show-select="false"
            :show-actions="false"
            disablePagination
          >
          </TypeDataTable>
        </template>

        <template v-slot:footer>
          <slot name="externalFooter">
          </slot>
        </template>
      </TypeDataTable>
    </div>
  </div>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import DatePicker from '@/components/common/DatePickerCommon.vue'
import closuresService from "@/services/closures/closures.service.js"
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue"
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"

import Vue from 'vue'

export default {
  name: "ClosureCustom",
  components: {
    FullScreenDialog,
    DatePicker,
    RangeDateSelector,
    TagsCategoriesAutocomplete,
    TypeDataTable,
    ExportExcelTable
  },
  data: function () {
    let props = this.$router.resolve({ name: "Payments" });

    return {
      loadingClosures: false,
      routeFather: props.href,
      startDate: undefined,
      endDate: undefined,
      closures: [],
      headers:[],
      page: 1, 
      rowPerPage: 200,
      totalPages: 1,
      totalRow: [],
      dataTableHeightValue: 400,
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      viewBar: undefined,
    }
  },
  props: {
    bus: {
      type: Object,
      default: new Vue()
    },
    canViewGhost: {
      type: Boolean,
      default: false
    },
  },
  mixins:[dateUtils],
  mounted: function () {
    if (!!this.bus) {
      this.bus.$on('reload', () => {
        if (!!this.startDate && !!this.endDate) this.fetchClosures()
      })
    }

    this.headers = [        
      { text: "Data", value: "date", type: "date"},
      { text: "Totale", value: "total", type: "price"},
      { text: "Parrucchiere Imponibile", value: "parImp", type: "price"},
      { text: "Parrucchiere Iva", value: "parIVA", type: "price"},
      { text: "Vendita Imponibile", value: "venImp", type: "price"},
      { text: "Vendita Iva", value: "venIVA", type: "price"},
      { text: "Estetica Imponibile", value: "estImp", type: "price"},
      { text: "Estetica Iva", value: "estIVA", type: "price"},
    ]
    if(!!this.viewBar.active){ 
      this.headers = [
        ...this.headers,
        { text: "Bar Imponibile", value: "barImp", type: "price"},
        { text: "Bar Iva", value: "barIVA", type: "price"},
        { text: "Bar Vendita Imponibile", value: "barVenImp", type: "price" },
        { text: "Bar Vendita Iva", value: "barVenIVA", type: "price" },
      ]
    }
    this.headers = [
      ...this.headers,
      { text: "Altro Imponibile", value: "otherImp", type: "price" },
      { text: "Altro IVA", value: "otherIVA", type: "price" }
    ]

    /*this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight;

    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);*/

    this.fieldsToExport = closuresService._fieldsToExport();
    this.worksheetName = "Export Corrispettivi";
    this.fileName =
      "Corrispettivi_" + this._buildDateToExport(new Date()) + ".xls";
  },
  methods: {
    async fetchExcel(){
      this.fetchClosures()
      let totalRow = this.totalRow[0]
      totalRow.date = "Totale"
      return [...this.closures, totalRow]
    },
    fetchClosures() {
      if (!this.startDate || !this.endDate) {
        this.$delegates.snackbar("Selezionare data di inizio e fine")
        return
      }

      let startDate = new Date(this.startDate), endDate = new Date(this.endDate)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)


      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      this.loadingClosures = true
      closuresService.closeDetails(startDate, endDate, { ghost: false }).then((results) => {
        this.closures = results.rows
        this.totalRow = results.totalRow
        this.loadingClosures = false
      })
    },
    setToday() {
      this.startDate = new Date()
      this.endDate = new Date()
    },
    setYesterday() {
      const yesterday = new Date(new Date().getTime() - 24 * 60 * 60000)
      this.startDate = yesterday
      this.endDate = yesterday
    },
    setThisMonth() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.startDate = firstDay

      var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.endDate = lastDay
    },
    setLastMonth() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.startDate = firstDay

      var lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.endDate = lastDay
    },
    setThisYear() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), 0, 1);
      firstDay.setHours(0)
      firstDay.setMinutes(0)
      this.startDate = firstDay

      var lastDay = new Date(today.getFullYear() + 1, 0, 0);
      lastDay.setHours(0)
      lastDay.setMinutes(0)
      this.endDate = lastDay
    },
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar",
    }
  },
}

</script>